<template>
	<div class="pui-form">
		<notificacioncontacto-modals :modelName="modelName"></notificacioncontacto-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<notificacioncontacto-form-header :modelPk="modelPk"></notificacioncontacto-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<!-- IDNOTCONTACTO -->
						<v-col cols="4">
							<pui-text-field
								:id="`idnotcontacto-notificacioncontacto`"
								v-model="model.idnotcontacto"
								:label="$t('notificacioncontacto.idnotcontacto')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- IDPUERTOLOCAL -->
						<v-col cols="4">
							<pui-text-field
								:id="`idpuertolocal-notificacioncontacto`"
								v-model="model.idpuertolocal"
								:label="$t('notificacioncontacto.idpuertolocal')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- IDAGENTE -->
						<v-col cols="4">
							<pui-text-field
								:id="`idagente-notificacioncontacto`"
								v-model="model.idagente"
								:label="$t('notificacioncontacto.idagente')"
								:disabled="formDisabled"
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- IDTERMINAL -->
						<v-col cols="4">
							<pui-text-field
								:id="`idterminal-notificacioncontacto`"
								v-model="model.idterminal"
								:label="$t('notificacioncontacto.idterminal')"
								:disabled="formDisabled"
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- EMAIL -->
						<v-col cols="4">
							<pui-text-field
								:id="`email-notificacioncontacto`"
								v-model="model.email"
								:label="$t('notificacioncontacto.email')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="250"
							></pui-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import notificacioncontactoActions from './NotificacioncontactoActions';
import notificacioncontactoModals from './NotificacioncontactoModals.vue';

export default {
	name: 'notificacioncontacto-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'notificacioncontacto-modals': notificacioncontactoModals
	},
	data() {
		return {
			modelName: 'notificacioncontacto',
			actions: notificacioncontactoActions.formActions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {},
	created() {}
};
</script>
